<script setup lang="ts">
const lawyerImagesByCountry = {
  FI: async () => ([
    (await import('~/assets/images/lawyers/senja-fi.webp?url')).default,
    (await import('~/assets/images/lawyers/hanna-fi.webp?url')).default,
    (await import('~/assets/images/lawyers/jaakko-fi.webp?url')).default,
  ]),
  SE: async () => ([
    (await import('~/assets/images/lawyers/arian-se.webp?url')).default,
    (await import('~/assets/images/lawyers/hanna-fi.webp?url')).default,
  ]),
  GB: async () => ([
    (await import('~/assets/images/lawyers/ashleigh-uk.webp?url')).default,
    (await import('~/assets/images/lawyers/heather-uk.webp?url')).default,
    (await import('~/assets/images/lawyers/hanna-fi.webp?url')).default,
  ]),
  DE: async () => ([
    (await import('~/assets/images/lawyers/katharina-de.webp?url')).default,
    (await import('~/assets/images/lawyers/hanna-fi.webp?url')).default,
  ]),
  PL: async () => ([
    (await import('~/assets/images/lawyers/tomasz-pl.webp?url')).default,
    (await import('~/assets/images/lawyers/oskar-pl.webp?url')).default,
    (await import('~/assets/images/lawyers/hanna-fi.webp?url')).default,
  ]),
} satisfies Record<App.Internals.Enums.Country, () => Promise<string[]>>

const { country } = useAuth()

const lawyers = asyncComputed(async () => country.value ? (await lawyerImagesByCountry[country.value]()).reverse() : Promise.resolve([]))
</script>

<template>
  <div
    class="flex flex-row-reverse items-center gap-0.5"
    style="justify-content: start;"
  >
    <div
      v-for="(lawyerImgUrl, index) in lawyers"
      :key="`lawyer-${index}`"
      class="h-8 w-6"
    >
      <img
        :src="lawyerImgUrl"
        class="relative block size-8 max-w-8 shrink-0 rounded-full ring-2 ring-white"
      >
    </div>
  </div>
</template>

import type { CSSProperties } from 'vue'

const BUTTON_ID = 'lawyer-chat-button'
const getButtonId = (country?: App.Internals.Enums.Country) => `${BUTTON_ID}-${country}`

const SR_ONLY: CSSProperties = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0',
}

const [useLawyerChatTriggerProvider, useLawyerChatTriggerOriginal] = createInjectionState(() => {
  const { country } = useAuth()

  const triggers = ref<Array<{ id: string, element: HTMLButtonElement }>>([])
  const button = shallowRef<HTMLButtonElement>()

  const registerTrigger = (trigger: HTMLButtonElement) => {
    const id = crypto.randomUUID()
    triggers.value.push({ id, element: trigger })
    return id
  }

  const unregisterTrigger = (id?: string) => {
    triggers.value = triggers.value.filter(t => t.id !== id)
  }

  const getButton = () => button.value ?? document.getElementById(getButtonId(country.value))

  const propagateTriggerClick = (event: MouseEvent) => {
    const button = getButton()
    if (!button) {
      return
    }

    button.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, button: 0, clientX: event.clientX, clientY: event.clientY }))
  }

  const createChatTrigger = () => {
    button.value = document.createElement('button')
    button.value.id = getButtonId(country.value)
    button.value.ariaHidden = 'true'
    button.value.type = 'button'
    Object.assign(button.value.style, SR_ONLY)

    document.body.appendChild(button.value)
  }

  const removeChatTrigger = () => {
    const button = getButton()
    if (!button) {
      return
    }

    document.body.removeChild(button)
  }

  watch(() => triggers.value.length, (length, oldLength) => {
    if (length && !oldLength) {
      createChatTrigger()
    }
    else if (!length) {
      removeChatTrigger()
    }
  }, { immediate: true })

  tryOnScopeDispose(() => {
    removeChatTrigger()
  })

  return {
    registerTrigger,
    unregisterTrigger,
    propagateTriggerClick,
  }
})

const useLawyerChatTrigger = () => {
  const chatTrigger = useLawyerChatTriggerOriginal()
  if (!chatTrigger) {
    throw new Error('Please call `useLawyerChatTriggerProvider` on the appropriate parent component')
  }

  return chatTrigger
}

export { useLawyerChatTrigger, useLawyerChatTriggerProvider }

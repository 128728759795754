<script setup lang="ts">
import DTButton from '@docue/docue-ui-v2/ui-components/DTButton/DTButton.vue'

withDefaults(defineProps<{
  buttonProps?: InstanceType<typeof DTButton>['$props']
}>(), {  })

const emit = defineEmits<{
  click: [MouseEvent]
}>()

const { $posthog } = useNuxtApp()

const button = useTemplateRef<HTMLButtonElement>('button')

const { registerTrigger, unregisterTrigger, propagateTriggerClick } = useLawyerChatTrigger()
const buttonId = ref<string>()

whenever(button, (button) => {
  buttonId.value = registerTrigger(button)
}, { immediate: true })

onBeforeUnmount(() => {
  unregisterTrigger(buttonId.value)
})

const onClick = (event: MouseEvent) => {
  $posthog.capture('lawyer_chat_clicked')
  propagateTriggerClick(event)
  emit('click', event)
}
</script>

<template>
  <!-- Chat trigger is handled by Intercom -->
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <DTButton
    v-bind="buttonProps"
    ref="button"
    class="lawyer-chat-button"
    @click="onClick"
  >
    <slot />
  </DTButton>
</template>
